// Loading
.loading-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.72);
	z-index: 9;
	.loading--content {
		@include heightAvailable();
		text-align: center;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.spinner-border {
			width: 5rem;
			height: 5rem;
			color: $lightBlue;
		}
		h6 {
			margin: 1.25rem 0;
		}
	}
}
