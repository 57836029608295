// Colors
$green: #0e6822;
$lightGreen: #4dd986;
$darkBlue: #19345b;
$btnColor: #19345b;
$btnHover: #41b14d;
$lightBlue: #398ec7;
$backgroundBlue: #ecf3f8;
$lightRed: #cf5947;
$white: #fff;
$textGray: #757575;
$textBlack: #393939;
$lightGray: #bfc3c8;
$silver: #fbfbfb;
$disable: #f1f1f1;
$borderBlue: #dee4ec;
$errored: #b94a48;
$toastSucess: #0e6822;
$toastLightSucess: #d3f7db;
$toastWarning: #e9b023;
$toastLightWarning: #fff1ce;
$toastDanger: #b94a48;
$toastLightDanger: #ffe9eb;
$processing: #c5b73d;
$approved: #2db24a;

// Styling
$letter-spacing: 0.0625rem;
