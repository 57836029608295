.footer-main {
	color: $darkBlue;
	font-weight: 500;
	.footer-main--link {
		background: $white;
		color: $darkBlue;
		font-weight: 500;
		font-size: 0.9rem;
		padding: 0 0.3125rem;
		border: 0;
		text-decoration: none;
		cursor: pointer;
		&:visited {
			color: $darkBlue;
		}
	}
	// Button bootstap footer
	button.btn,
	.footer-main--link,
	a {
		background: $white;
		color: $darkBlue;
		&:hover {
			color: $green;
			background-color: $white;
		}
	}
}
.modal-title.h4 {
	text-transform: uppercase;
}
.politica-privacidade-main {
	margin: 1.25rem 1.9rem;
	ol {
		margin: 1.9rem 0rem;
	}
	h6 {
		margin-bottom: 1.25rem;
	}
}
