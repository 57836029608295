.faq-main--accordion {
	.accordion-body {
		padding: 2.5rem 1.9rem;
		letter-spacing: initial;
	}
	h2 {
		margin: 0;
	}
	.accordion-button:focus {
		box-shadow: unset;
	}
	.accordion-button:not(.collapsed) {
		background-color: $backgroundBlue;
	}
}
