.saldos-page-balance {
	border-radius: 0.8rem;
	border: 0.125rem solid #ecf3f8;
	font-size: 1.3rem;
	height: 5.625rem;
	width: 10rem;
	background-color: #398ec7;
	border-top-left-radius: 0rem;
	border-top-right-radius: 0rem;

	p {
		font-size: 0.8rem;
		color: white;
	}

	&-value {
		color: white;
	}
}

.saldos-page-balance-date {
	border-top-left-radius: 0.8rem;
	border-top-right-radius: 0.8rem;
	border: 2px solid #ecf3f8;
	width: 10rem;
	p {
		font-size: 0.8rem;
	}

	div {
		color: #398ec7;
	}
}
