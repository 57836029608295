@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.App {
	overflow: hidden;
	font-family: 'Poppins', sans-serif;
	font-size: 0.9rem;
	letter-spacing: $letter-spacing;
	margin: 0;
	h1 {
		color: $lightBlue;
		font-size: 2.3rem;
		line-height: inherit;
	}
	h2 {
		color: $lightBlue;
		font-weight: 500;
		margin: 2rem 0;
	}
	h3 {
		color: $lightBlue;
		font-weight: 500;
		margin: 1rem 0;
	}
	h4 {
		color: $lightBlue;
		font-size: 1.3rem;
		font-weight: 500;
	}
	h5 {
		color: $lightBlue;
		line-height: inherit;
	}
	h6 {
		text-transform: uppercase;
		color: $textGray;
		font-weight: 200;
	}
	p,
	label {
		color: $textGray;
	}
	.pDark {
		color: $darkBlue;
	}
	.service-btn {
		@include buttonTemplate();
	}
	.download-btn {
		@include buttonForm();
	}
	.service-btn--white {
		background-color: $white;
		color: $darkBlue;
		border: 0.125rem solid $darkBlue;
		&:hover {
			border-color: $btnHover;
			background: $btnHover;
			color: $white;
		}
	}
	.fake-btn {
		height: 3rem;
		padding: 0.7rem 1.25rem;
	}
	// Button bootstap
	button.btn {
		&:active {
			background: $btnHover;
		}
	}
	.iframe-container {
		position: relative;
		width: 100%;
		height: 39em;

		iframe {
			height: 100%;
			width: 79%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
// Modal
.modal-content {
	.modal-header {
		padding: 1.25rem 2.5rem;
	}
}

// Page with circle image
.page-bubble-main {
	.col-md-8 {
		width: 100%;
	}
}

// MP post service
.mp-advantage-card {
	&--wrapper {
		@include borderCardWhiteBG();
	}
}

// Highlight
.highlight {
	@include highlight(1.25rem, -0.9375rem);
}
.highlight-portalpro {
	@include highlight(1.25rem, 0);
}

// Certified Emails advantages
.certified-emails-advantage-card {
	&--wrapper {
		@include borderCardWhiteBG();
	}
}
