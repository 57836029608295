.home-main {
	&--cover {
		overflow: hidden;
		position: relative;
	}
	&--cover__title {
		margin: 5.7rem 0 3rem 10%;
		width: 43%;
	}
	&--cover__anchor {
		width: fit-content;
		h5 {
			color: $lightBlue;
			margin: 0;
		}
		a {
			color: $lightBlue;
			font-size: 1.8rem;
		}
	}
	&--cover__img {
		position: absolute;
		background: url('../../../public/img/bubbles.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		width: 37.5rem;
		height: 43.75rem;
		float: right;
		right: 10%;
		bottom: -1.875rem;
		z-index: -1;
	}
	&--cover__title__bubbles-left {
		position: absolute;
		background: url('../../../public/img/cover_bubbles.png');
		width: 37.5rem;
		height: 43.75rem;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: top;
		left: 6%;
		z-index: -1;
	}
	&--highlight {
		background-color: $backgroundBlue;
		padding: 3rem 0;

		&__section {
			border: 0.1875rem solid #ecf3f8;
		}

		&-saldos {
			background-color: white;
		}

		&__section-promo {
			color: #fff;
			font-size: 1.7em;
			font-weight: 900;
			text-align: center;
			position: absolute;
			background: #d13d26;
			right: 0;
			top: 0;
			border-top-right-radius: 1.7rem;
			border-bottom-left-radius: 2.875rem;
			padding: 0.6em;
		}
	}

	&--highlight__section {
		position: relative;
		background-color: $white;
		padding: 3rem 3.3rem 2rem;
		border-radius: 1.875rem;
		height: 100%;
		@include centerContentVertical(space-between);
		h2 {
			margin: 1.2rem 0;
		}
	}
	.other-services {
		position: relative;
		border-radius: 2rem;
		height: 100%;
		min-width: 18.75rem;
		@include centerContentVertical();
		padding: 0 2rem 1.5rem;
		background-color: white;
		border: 0.1875rem solid $backgroundBlue;
		h4 {
			margin: 1rem 0;
			width: 100%;
			line-height: 1.5;
		}
		h6 {
			margin: 0;
		}
		&--promo {
			color: #fff;
			font-size: 1.5em;
			font-weight: 900;
			text-align: center;
			position: absolute;
			background: #41b14d;
			right: 0%;
			top: -9%;
			padding: 0.4em 1.3em 2em;
			border-top-left-radius: 2em;
			border-top-right-radius: 3.2em;
			border-bottom-left-radius: 2rem;
			z-index: -1;
			border: 0.3rem solid transparent;
		}
		&--img {
			@include centerContentVertical(center);
			min-height: 9.375rem;
			width: 13.125rem;
			margin: 0 auto;
		}
		&--btn {
			align-items: end;
			height: -webkit-fill-available;
		}
	}
	.other-services--2Cols {
		.other-services--content {
			min-width: 25.5rem;
		}
	}
	&--partnerships {
		padding-top: 6em;
		background-color: white;
	}
	&--partnerships-saldos {
		background-color: #eaf1f9;
	}
}

.accumulated-balance {
	background-color: #eaf1f9;
	position: relative;

	&-img {
		max-height: 25em;
		margin-left: 8em;
	}

	&-title {
		margin-top: 3em !important;
		text-align: right;
	}

	&-title {
		padding-right: 3em;
	}
	&-description {
		padding-right: 5em;
	}

	&-advantages {
		color: $approved;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 2.2rem;
		letter-spacing: 0.1em;
	}

	&-date {
		margin-left: 9em;
	}

	&-values {
		z-index: 5;
		position: relative;
		width: 100%;
		background-color: #8cc4e8;
		border-top-left-radius: 50%;

		&::after {
			content: '';
			position: absolute;
			border-right: 900px solid #8cc4e8;
			top: 0;
			right: 0;
			height: 100%;
			transform: rotate(180deg);
			transform-origin: right;
		}

		&-title {
			margin-left: 7em;
		}

		&-box {
			background-color: white;
			color: $lightBlue;
			border-radius: 2em;
			width: 30em;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			transform: translate(-35px, 0px);

			&-content {
				&-balance {
					gap: 0.5em;

					svg {
						vertical-align: baseline !important;
					}
				}
			}
		}
	}

	&-nobalance {
		color: $lightBlue !important;
		font-size: 0.8rem;

		&-link {
			color: $lightBlue !important;
		}
	}

	&-win {
		color: $approved;
		font-size: 2.2rem;
		padding-right: 0.2em;
	}

	&-modal-title {
		color: $lightBlue;
	}

	&-modal-body {
		color: $textGray;
	}

	&-modal-btn {
		background: #19345b;
		line-height: 2.8rem;
		font-weight: 600;
		text-transform: uppercase;
		padding: 0 1.25rem;
		border-radius: 1.25rem;
		border: 0;

		&:hover {
			background: $btnHover;
		}
	}
}
