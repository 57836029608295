//Toast
.toast-container {
	.toast {
		border: 0;
		padding: 1rem;
		position: relative;
		margin-top: 1.25rem;
		margin-right: 1.25rem;
	}
	.toast-header {
		border-bottom: 0;
		background: transparent;
	}
	svg {
		position: absolute;
		left: -2.5rem;
		top: 0;
		bottom: 0;
		margin: auto;
		border: 0.1875rem solid;
		border-radius: 1.5625rem;
		padding: 0.4rem;
		height: 2.1875rem;
		width: 2.1875rem;
	}
	button {
		position: absolute;
		width: 3.125rem;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	.toast-body {
		position: relative;
		width: calc(100% - 6.25rem);
		margin: auto;
	}
	.toast-success {
		background: $toastLightSucess;
		svg {
			color: $toastSucess;
		}
	}
	.toast-warning {
		background: $toastLightWarning;
		svg {
			color: $toastWarning;
		}
	}
	.toast-danger {
		background: $toastLightDanger;
		svg {
			color: $toastDanger;
		}
	}
}
