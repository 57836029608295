.lusopay-info--content {
	border: 0.1875rem solid $backgroundBlue;
	border-radius: 1.25rem;
	padding: 2rem;
	height: 100%;
	background-color: $silver;

	&_img {
		@include maxWidthAvailable();
		&-last {
			width: 7.5rem;
		}
	}
}
