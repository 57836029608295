/*nordstreet*/
.nordstreet-main {
	.nordstreet-custom-card {
		@include borderCard();
	}
	h4 {
		color: $lightBlue;
	}
	svg {
		color: $lightBlue;
		font-size: 3.5rem;
		width: 100%;
		margin: 1.25rem 0;
	}
}
