.certified-emails-table {
	border-collapse: collapse;

	thead tr {
		color: $textGray;
		font-size: 0.75rem;
		border-bottom: 0.1875rem solid #ecf3f8;

		th {
			font-weight: 100;
		}
	}

	tr {
		border-radius: 1.2em;

		th,
		td {
			padding: 0.8em 1.5em;
		}
	}

	tbody tr {
		border-bottom: 0.1875rem solid #ecf3f8;
	}
}
