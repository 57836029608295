// Card with blue borders
.card-main {
	&--btn {
		text-align: center;
		margin-top: 1.25rem;

		.service-btn {
			padding: 0.7rem 1.25rem;
		}
	}
	&--border {
		position: relative;
		@include borderCard();
		@include centerContentVertical(space-between);
	}
	.card-main--header svg {
		@include cardIcons;
	}
	ul {
		padding-left: 1rem;
	}
	li {
		margin: 0.3rem 0;
	}
	h4 {
		min-height: 3.1rem;
	}
	img {
		max-width: 13.125rem;
		margin: 30px auto;
	}
}

.energy-main {
	.card-main {
		img {
			max-width: 17rem;
		}
	}
}

// Card to use over a blue background
.card-blue {
	background-color: $backgroundBlue;
	padding: 2rem 0 3.5rem 0;
	&--box {
		@include centerContentVertical(space-between);
		&-background {
			height: 100%;
			background-color: $white;
			border-radius: 1.25rem;
			padding: 1rem 1.5rem;
			svg {
				color: $lightBlue !important;
				font-size: 3.5rem;
				width: 100%;
				margin: 1.25rem 0;
			}
		}
	}
}
