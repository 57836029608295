// eletricidade
.energy-main {
	position: relative;

	.energy-partner--content {
		@include borderCard();

		img {
			max-width: 13.125rem;
		}
	}

	.energy-partner-wrapper {
		height: 100%;
		@include centerContentVertical(space-between);
	}

	.service-btn {
		white-space: nowrap;
		margin: 0;
		max-width: unset;
		min-width: unset;
		text-transform: unset;
	}

	.page-header--img-promo {
		&:after {
			content: 'Ganhe 18€';
			font-size: 3rem;
			position: absolute;
			right: 0;
			max-width: 12.5rem;
			text-align: center;
			color: $lightGreen;
			font-weight: 900;
		}
	}
}

// Promo color text
.promo-text {
	color: $lightGreen;
}
