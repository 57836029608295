.header-main--wrapper {
	position: relative;
}
.header-main {
	padding: 0.875rem 0;
	border-bottom: 0.0625rem solid $borderBlue;
	a {
		margin-right: 1.25rem;
		text-decoration: none;
		color: $darkBlue;
		&:hover {
			color: $green;
		}
	}
	.header-main__logo img {
		height: 2.5rem;
	}
	.header-main__button {
		background-color: $darkBlue;
		border: 0;
		color: $white;
		font-size: 1rem;
		padding: 0.5rem 1.25rem;
		border-radius: 0.625rem;
		&:hover {
			background-color: $green;
			color: $white;
		}
	}
}
