@mixin buttonTemplate() {
	margin: 20px;
	width: fit-content;
	max-width: 18.75rem;
	min-width: 12.5rem;
	height: 2.8rem;
	background: $btnColor;
	color: $white;
	font-size: 0.9rem;
	line-height: 2.8rem;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: unset;
	text-align: center;
	padding: 0 1.25rem;
	border-radius: 1.25rem;
	border: 0;
	&:hover {
		background: $btnHover;
		color: $white;
	}
}
@mixin buttonForm() {
	margin: 1.25rem 0;
	background: #f8f9fa;
	border-radius: 0.4rem;
	border: 0.0625rem solid #bfc3c8;
	padding: 0.37rem 0.82rem;
	color: #212529;
	font-size: 1rem;
	text-decoration: unset;
	text-align: center;
	letter-spacing: 0;
	&:hover,
	&:active {
		border: 0.0625rem solid #bfc3c8;
		background: #e9ecef;
		color: #212529;
	}
}
@mixin borderCard() {
	border: 0.1875rem solid $backgroundBlue;
	border-radius: 1.25rem;
	padding: 2rem;
	height: 100%;
	min-width: 18.75rem;
	h4 {
		color: $lightBlue;
		width: calc(100% - 2.5rem);
	}
}
@mixin borderCardWhiteBG() {
	@include borderCard();
	background-color: white;
}

@mixin highlight($top, $left) {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: $top;
		left: $left;
		width: 100%;
		height: 1.2rem;
		background: url('../../public/img/highlight.png');
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}

@mixin cardIcons() {
	position: absolute;
	color: $lightBlue;
	font-size: 2.2rem;
	right: 1.9rem;
	top: 1.8rem;
}

@mixin centerContentVertical($content: unset) {
	display: flex;
	flex-direction: column;
	justify-content: $content;
}

@mixin maxWidthAvailable() {
	max-width: -webkit-fill-available;
	max-width: -moz-fill-available;
	max-width: -ms-fill-available;
	max-width: stretch;
}
@mixin heightAvailable() {
	height: -webkit-fill-available;
	height: -moz-fill-available;
	height: -ms-fill-available;
	height: stretch;
}
