@media only screen and (max-width: 2300px) {
	.accumulated-balance-values-sm {
		display: none;
	}
	.wave-img {
		right: -6% !important;
	}
}

@media only screen and (max-width: 1600px) {
	.accumulated-balance {
		&-img {
			height: 20em;
		}
	}
}

@media only screen and (max-width: 1400px) {
	.home-main {
		.home-main--cover__title {
			width: 50%;
			margin-left: 6%;
		}
		.home-main--cover__img {
			right: -8.125rem;
		}

		.other-services--promo {
			top: -8% !important;
		}
	}
	.App {
		.energy--wrapper > div {
			margin-top: 1.25rem;
		}
		.service-btn {
			width: 100%;
		}
	}
	.wave-img {
		right: -7% !important;
	}
}
@media only screen and (max-width: 1199px) {
	.home-main {
		.other-services--promo {
			top: -9% !important;
		}
		&-other--partnerships {
			gap: 1rem !important;
		}
	}
	.accumulated-balance {
		&-row {
			flex-direction: column;
		}

		&-values {
			background-color: transparent !important;
			align-items: center;
			padding-bottom: 2em;
			width: auto !important;

			&::after {
				content: none !important;
			}

			&-box {
				width: 35em !important;
				transform: none !important;
			}

			&-title {
				color: $lightBlue !important;
				margin-left: 0 !important;
				text-align: center;
			}

			&-sm {
				display: block;
			}
		}

		&-link {
			display: none;
		}

		&-date {
			color: $lightBlue !important;
			margin-left: 0 !important;
		}
	}
	.background {
		display: none;
	}
	.wave-img {
		right: -10% !important;
	}
}
@media only screen and (max-width: 991px) {
	.App {
		.header-main {
			.navbar-toggler:focus {
				box-shadow: 0 0;
			}
			.navbar-nav {
				a {
					padding: 1.25rem 0;
					width: 100%;
				}
			}
			.menu--main-btn {
				position: absolute;
				right: 0.6rem;
				top: 0.3rem;
			}
			.header-main__button {
				display: none;
				margin-left: 1rem;
			}
		}
		.home-main {
			.home-main--cover__title {
				width: 95%;
				margin: 10% auto;
			}
			.home-main--cover__img {
				display: none;
			}
			.home-main--highlight__section {
				padding: 2.5rem 2.5rem 1.5rem;
			}
			.other-services--promo {
				top: -8% !important;
			}
		}
		.form--container {
			h4 {
				text-align: left;
				margin: 0 0 0.6rem;
			}
		}
		.page-title-header img {
			width: auto !important;
			max-height: 9.375rem;
			margin: 1.9rem 0;
		}
		.accumulated-balance {
			&-values-title,
			&-values-box {
				margin-right: 0;
				width: auto;
			}

			&-description,
			&-title {
				padding: 0 1em !important;
			}
		}
	}
}
@media only screen and (min-width: 991px) {
	.header-saldos__link {
		display: none;
	}
}
@media only screen and (max-width: 991px) {
	.home-main {
		&--highlight {
			&__section-promo {
				padding: 0.5em !important;
				font-size: 1.6em !important;
			}
		}
	}
	.wave-img {
		display: none !important;
	}
	.wave-mobile-img {
		display: block !important;
		left: -0.1%;
		width: 100.1%;
		top: 90%;
	}

	.mp-campaign-right {
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		padding: 2em !important;
	}
	.mp-campaign-left {
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
}
@media only screen and (max-width: 768px) {
	.footer-main div {
		width: fit-content;
		margin: auto;
	}
	.footer-main div:last-child {
		margin-top: 1rem;
	}
	.App {
		.page-header {
			.title-icon {
				margin-top: 3rem;
				text-align: center;
				img {
					max-height: 10rem;
				}
			}
			.title-img {
				max-width: 65%;
				margin: 2rem auto;
			}
		}
		overflow: hidden;

		.home-main {
			.other-services--promo {
				top: -11% !important;
			}
		}

		.accumulated-balance {
			&-img {
				display: none;
			}
			&-description,
			&-title {
				text-align: center !important;
				padding: 0 !important;
			}
		}
	}
}

@media only screen and (max-width: 530px) {
	.accumulated-balance {
		&-values-box {
			width: auto !important;
		}
	}
}

@media only screen and (max-width: 475px) {
	.wave-mobile-img {
		top: 95%;
	}
}
