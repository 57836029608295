.page-header {
	&-logo {
		max-height: 5rem;
	}
	h2 {
		margin-top: 0.7rem;
	}
	.title-img {
		img {
			@include maxWidthAvailable();
		}
	}
	.title-icon {
		img {
			max-height: 12.5rem;
		}
	}
}
.mp-main {
	.page-header-logo {
		min-width: auto;
		min-height: 7rem;
	}
}
.lusopay-info {
	.page-header-logo {
		margin-left: -20px;
	}
}
.portalPro-main {
	h2 {
		margin-top: 2rem;
	}
}
.nordstreet-main {
	.page-header-logo {
		margin-left: -10px;
	}
}
.certifiedemails-main {
	.page-header-logo {
		max-height: 2.5rem;
	}
}
