@import '../../styles/settings';

.endesa-popup {
	label {
		color: #1e2f3e;
		margin-left: 0.4em;
		cursor: pointer;
		transition: color 0.3s;

		&:hover {
			color: $green;
		}
	}
}
