.balance-main {
	.submit-invoice--main {
		@include centerContentVertical(space-between);
		background: $lightBlue;
		border-radius: 1.25rem;
		padding: 1.7rem 1rem;
		h3 {
			font-weight: 100;
			color: $white;
			text-align: center;
		}
		.service-btn--white {
			border-color: $white;
			color: $lightBlue;
			&:hover {
				border-color: $lightGreen;
				background-color: $lightGreen;
			}
		}
	}
	.balance-value--available {
		@include centerContentVertical(flex-end);
		background: url('../../../public/img/balance-bubble-total.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		height: 11.25rem;
		padding-bottom: 1.9rem;
		h1 {
			font-size: 3rem;
		}
		h1,
		p {
			color: $white;
			text-align: center;
			margin: 0;
			padding-left: 1.25rem;
		}
	}
	.balance-value--total {
		background-color: $lightGreen;
		color: $white;
		font-size: 1.2rem;
		text-align: center;
		border-radius: 0.6rem;
		padding: 0.6rem 0.9rem;
		margin-top: 1em;
	}
	.balance-partner {
		border: 0.1875rem solid $backgroundBlue;
		border-radius: 0.6rem;
		padding: 0.6rem 0.9rem;
		margin-top: 1em;
		svg {
			color: $lightBlue;
			font-size: 1.3rem;
			margin-right: 0.5rem;
		}
	}
}
.balance-invoice--table {
	border-radius: 1.25rem;
	thead th {
		font-weight: 100;
		color: $textGray;
		font-size: 0.75rem;
	}
	tr {
		text-align: center;
	}
	tbody tr {
		font-size: 0.9rem;
	}
	.processing,
	.approved,
	.canceled {
		font-weight: 500;
	}
	.approved {
		color: $approved;
	}
	.processing {
		color: $processing;
	}
	.canceled {
		color: $errored;
	}
}
